<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="企业搜索" prop="enterprise_id">
                <el-select
                    v-model="form.enterprise_id"
                    filterable
                    placeholder="选择企业"
                    class="enterprisec"
                    clearable
                    @change="enterprise_change"
                >
                    <el-option
                        v-for="enterprise in AllEnterprise"
                        :key="enterprise.id"
                        :label="enterprise.name"
                        :value="enterprise.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="部门搜索" prop="department_id">
                <el-select
                    v-model="form.department_id"
                    filterable
                    placeholder="选择部门"
                    class="department_idc"
                    clearable
                >
                    <el-option
                        v-for="department in AllDepartment"
                        :key="department.id"
                        :label="department.name"
                        :value="department.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="被考核人" prop="staff_id">
                <el-select
                    v-model="form.staff_id"
                    filterable
                    placeholder="选择被考核人"
                    class="staff_idc"
                    clearable
                >
                    <el-option
                        v-for="staff in AllStaff"
                        :key="staff.id"
                        :label="staff.name"
                        :value="staff.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="state">
                <el-select
                    v-model="form.state"
                    filterable
                    placeholder="选择状态"
                    class="statec"
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['未完成','已完成']"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >生成上季度绩效考核表</el-button
            >
            <table-staff-search-bar-dialog-component
                :SearchBarDialogVisible="SearchBarDialogVisible"
                :SearchBarDialogData="SearchBarDialogData"
                @closeSearchBarDialog="closeSearchBarDialog"
                @search="search"
            ></table-staff-search-bar-dialog-component>
        </el-form>
    </el-card>
</template>

<script>
import { table_staff_staff_request } from '@/network/kpi/TableStaff.js'
import { enterprise_list_request,department_list_request,staff_list_request } from '@/network/list.js'
import TableStaffSearchBarDialogComponent from './TableStaffSearchBarDialogComponent'

export default {
    name: 'TableStaffSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                state: '',
                page: 1
            },
            AllEnterprise: [],
            AllDepartment: [],
            AllStaff: [],
            SearchBarDialogVisible: false,
            SearchBarDialogData: []
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.get_kpi_staff()
            this.SearchBarDialogVisible = true
        },
        enterprise_change(e) {
            this.AllDepartment = []
            this.form.department_id = ''

            if(e==='') {
                return
            }

            department_list_request({enterprise_id:e})
                .then((s) => {
                    if (s.status === 0) {
                        this.AllDepartment = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_enterprise() {
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        closeSearchBarDialog() {
            this.SearchBarDialogVisible = false
        },
        get_kpi_staff() {
            table_staff_staff_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.SearchBarDialogData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {
        add_auth: String
    },
    created() {
        this.get_all_enterprise()
        this.get_all_staff()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        TableStaffSearchBarDialogComponent
    },
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>