import request from '../request'

//个人绩效列表
export const table_staff_index_request = p => {
    return request({
        method:'GET',
        url:'table_staff/index',
        params: p
    })
}

//个人绩效详情
export const table_staff_details_request = id => {
    return request({
        method:'GET',
        url:'table_staff/details',
        params: {
            id
        }
    })
}

//获取上季度需要生成的员工
export const table_staff_staff_request = () => {
    return request({
        method:'GET',
        url:'table_staff/staff'
    })
}

//生成上季度绩效考核表
export const table_staff_adds_request = () => {
    return request({
        method:'POST',
        url:'table_staff/adds'
    })
}