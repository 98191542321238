<template>
    <div v-loading.fullscreen.lock="loading">
        <table-staff-search-bar-component
            add_auth="生成上季度绩效考核表"
            @search="get_table_staff_index"
        ></table-staff-search-bar-component>
        <common-table-component
            details_auth="个人绩效详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_table_staff"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_table_staff_index"
        ></common-page-component>
        <table-staff-edit-component
            :dialogFormVisible="fdialogFormVisible"
            :details_data="ftable_staff_details_data"
            @exitDialog="dialogExit"
        ></table-staff-edit-component>
    </div>
</template>

<script>
import { table_staff_index_request,table_staff_details_request } from '@/network/kpi/TableStaff.js'

import TableStaffSearchBarComponent from '@/components/kpi/TableStaff/TableStaffSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import TableStaffEditComponent from '@/components/kpi/TableStaff/TableStaffEditComponent'

export default {
    name: 'TableStaffView',
    data() {
        return {
            fid: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                state: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '考核表名称',
                    minWidth: '300px',
                    show_overflow_tooltip: true
                },{
                    prop: 'state',
                    label: '状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '未完成'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '已完成'
                            }
                        }
                    }
                },{
                    prop: 'staff_name',
                    label: '被考核人',
                    minWidth: '100px'
                },{
                    prop: 'sum_score',
                    label: '总分',
                    minWidth: '100px'
                }
            ],
            ftable_staff_details_data: {}
        }
    },
    computed: {},
    methods: {
        get_table_staff_index(param = {}) {
            this.loading = true
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.department_id = param.department_id ?? this.cond.department_id
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id
            this.cond.state = param.state ?? this.cond.state
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            table_staff_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_table_staff(id) {
            this.fdialogFormVisible = true
            table_staff_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.ftable_staff_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_table_staff_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        TableStaffSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        TableStaffEditComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>