<template>
    <div>
        <el-dialog
            :title="details_data.name"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="95%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <fieldset class="fieldsetc">
                    <legend class="legendc">基础信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="3"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                        size="small"
                    >
                        <el-descriptions-item :span="2">
                            <template slot="label">
                                <span>所属企业</span>
                            </template>
                            <span>{{details_data.enterprise_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>考核状态</span>
                            </template>
                            <el-tag size="mini" type="success" v-if="details_data.state">已完成</el-tag>
                            <el-tag size="mini" type="info" v-else>未完成</el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>所属部门</span>
                            </template>
                            <span>{{details_data.department_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>被考核人</span>
                            </template>
                            <span>{{details_data.staff_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>总得分</span>
                            </template>
                            <span>{{details_data.sum_score === null ?  '-' : details_data.sum_score}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </fieldset>
                <fieldset class="fieldsetc">
                    <legend class="legendc">考核详情</legend>
                    <el-table
                        :data="details_data.content"
                        :span-method="objectSpanMethod"
                        highlight-current-row
                        stripe
                        border
                        size="small"
                        style="width: 99%;"
                    >
                        <el-table-column align="center" type="index"></el-table-column>
                        <el-table-column
                            prop="title"
                            label="考核分类"
                            min-width="100px"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            label="项数"
                            min-width="70px"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row['rank']===1">第一项</el-tag>
                                <el-tag v-if="scope.row['rank']===2">第二项</el-tag>
                                <el-tag v-if="scope.row['rank']===3">第三项</el-tag>
                                <el-tag type="danger" v-if="scope.row['rank']===4">第四项</el-tag>
                                <el-tag type="danger" v-if="scope.row['rank']===5">第五项</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="content"
                            label="考核内容"
                            min-width="200px"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="score_value"
                            label="得分"
                            min-width="50px"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <span style="font-weight: bold;font-size: 20px;color: #ADADAD;" v-if="scope.row['score_value']===null">-</span>
                                <span style="font-weight: bold;font-size: 20px;color: #EA0000;" v-if="scope.row['score_value']===0">{{scope.row['score_value']}}</span>
                                <span style="font-weight: bold;font-size: 20px;color: #2828FF;" v-if="scope.row['score_value']===1">{{scope.row['score_value']}}</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
                            prop="score_note"
                            label="评语"
                            min-width="200px"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <span style="text-align: left;white-space: pre-line;">{{scope.row['score_note']}}</span>
                            </template>
                        </el-table-column> -->
                    </el-table>
                    <el-descriptions
                        class="margin-top"
                        :column="2"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                        size="small"
                        style="width: 99%;"
                    >
                        <el-descriptions-item>
                            <template slot="label">
                                <span>总分</span>
                            </template>
                            <span style="font-weight: bold;font-size: 18px;"><span style="color: #2828FF;">30</span><span>&nbsp;分</span></span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>得分</span>
                            </template>
                            <span style="font-weight: bold;font-size: 18px;">{{details_data.sum_score === null ?  '-' : details_data.sum_score}}&nbsp;分</span>
                        </el-descriptions-item>
                        <el-descriptions-item :span="2">
                            <template slot="label">
                                <span>评分说明</span>
                            </template>
                            <span v-html="explain"></span>
                        </el-descriptions-item> 
                    </el-descriptions>
                </fieldset>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    plain
                    @click="closeDialog()"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'TableStaffEditComponent',
    data() {
        return {
            login_loading: false,
            mergeObj: {},
            mergeArr: ['title']
        }
    },
    computed: {
        dialogFormTitle() {
            return '标题'
        },
        explain() {
            let html =''
            html += '<span>1、价值观考核分为 6 大类目，每类分 5 项，每项分数为1分，共30项，合计30分。</span><br />'
            html += '<span>2、每项有【值得赞扬】和【仍需努力】两种评价，分别对应1分和0分。</span><br />'
            html += '<span>3、每种类目第1-3项，所有评委里面只要有一个人评价为【仍需努力】，则该项最终评价为【仍需努力】。</span><br />'
            html += '<span>4、每种类目第4-5项，所有评委里面只要有一个人评价为【值得赞扬】，则该项最终评价为【值得赞扬】。</span><br />'
            html += '<span style="color: #FF0000;">5、本考核表最终解释权归武汉宇圣通人事部所有，如对得分结果有疑问，可联系人事部进行核查。</span><br />'
            return html
        }
    },
    methods: {
        closeDialog() {
            this.$emit('exitDialog')
        },
        getSpanArr(data) {
            this.mergeArr.forEach((key, index1) => {
                let count = 0; // 用来记录需要合并行的起始位置
                this.mergeObj[key] = []; // 记录每一列的合并信息
                data.forEach((item, index) => {
                    // index == 0表示数据为第一行，直接 push 一个 1
                    if(index === 0) {
                        this.mergeObj[key].push(1); 
                    } else {
                        // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
                        if(item[key] === data[index - 1][key]) { 
                            this.mergeObj[key][count] += 1;
                            this.mergeObj[key].push(0);
                        } else {
                            // 如果当前行和上一行其值不相等 
                            count = index; // 记录当前位置 
                            this.mergeObj[key].push(1); // 重新push 一个 1
                        }
                    }
                })
            })
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            this.getSpanArr(this.details_data.content)
            // 判断列的属性
            if(this.mergeArr.indexOf(column.property) !== -1) { 
                // 判断其值是不是为0 
                if(this.mergeObj[column.property][rowIndex]) { 
                    return [this.mergeObj[column.property][rowIndex], 1]
                } else {
                    // 如果为0则为需要合并的行
                    return [0, 0]; 
                }
            }
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        details_data: {
            type: Object,
            default() {
                return {}
            },
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {}
}
</script>

<style lang='less'></style>