<template>
    <div>
        <el-dialog
            :visible.sync="SearchBarDialogVisible"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeSearchBarDialog()"
            :title="title"
        >
            <common-table-component
                :col_arr="col_arr"
                :table_data="SearchBarDialogData"
                table_size="mini"
            ></common-table-component>
            <div slot="footer" class="dialog-footer">
                <el-button
                    type="success"
                    plain
                    @click="submitDialog()"
                    >确定</el-button
                >
                <el-button
                    plain
                    @click="closeSearchBarDialog()"
                    >取消</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { table_staff_adds_request } from '@/network/kpi/TableStaff.js'

import CommonTableComponent from '@/components/common/CommonTableComponent'
export default {
    name:'',
    data(){
        return {
            col_arr: [
                {
                    prop: 'enterprise_name',
                    label: '所属企业',
                    minWidth: '220px'
                },{
                    prop: 'department_name',
                    label: '所属部门',
                    minWidth: '130px'
                },{
                    prop: 'staff_name',
                    label: '被考核人',
                    minWidth: '90px'
                },{
                    prop: 'entry_date',
                    label: '入职日期',
                    minWidth: '120px'
                }
            ]
        }
    },
    computed:{
        title() {
            const num = this.SearchBarDialogData.length
            return '本次考核共'+num+'人'
        }
    },
    methods:{
        closeSearchBarDialog() {
            this.$emit('closeSearchBarDialog')
        },
        submitDialog() {
            this.$confirm(
                '是否确生成上季度绩效考核表？（无法撤销，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    table_staff_adds_request()
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('上季度绩效考核表已生成!')
                                this.closeSearchBarDialog()
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        }
    },
    filters:{},
    props:{
        SearchBarDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        SearchBarDialogData: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CommonTableComponent
    },
    watch:{}
}
</script>

<style>
</style>